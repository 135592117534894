<script setup lang="ts">
import { showToast } from 'vant'
import { validDayColor, validDayTextColor } from '~/utils/vip'
import icon_refresh from '~/assets/image/icon/u_sync.png'
import icon_hint from '~/assets/image/icon/icon_hint.png'
import bg1 from '~/assets/image/icon/bg1.png'
import icon_gold from '~/assets/image/icon/icon_gold@2x.png'
import icon_close from '~/assets/image/icon/icon_close.png'
import Frame from '~/assets/image/icon/Frame.png'
import { OpenVipApi } from '~/net/apis/open_vip_api'
import { formatValidDay } from '~/utils/num_extension'

const router = useRouter()
const onClickLeft = () => history.back()
function save() {
  router.push('/flowRecord')
}
const list = ref<Vip[]>([])
const text = ref<any>({})
const money = ref(0)
const chooseIndex = ref(0)
const show = ref(false)
const showLoading = ref(false)
const showLoading1 = ref(false)
const showLoad = ref(false)
const showPay = ref(false)
const tabController = ref(0)
async function getCoin() {
  const res = await OpenVipApi.coinBalance()
  money.value = res.data.balance
  console.log(res)
}
getCoin()
async function getVipList() {
  const { data } = await OpenVipApi.vipCardList()
  list.value = data
  text.value = list.value[0]
}

getVipList()

function onChange(index: any) {
  chooseIndex.value = index
  text.value = list.value[index]
}
async function pay() {
  showLoad.value = true
  showLoading.value = true
  show.value = false
  if (money.value < text.value.discountPrice) {
    showLoading.value = false
    showLoad.value = false
    showToast('您的金币不足，请先充值金币')
    return
  } else {
    showLoading.value = false
    showLoading1.value = true
    await coinBuy()
    setTimeout(() => {
      showLoading1.value = false
      showLoad.value = false
      router.push('/payResult')
    }, 1000)
  }
  showLoading1.value = true
}
const rotation = ref(0)
// "eventId": null,
// "price": 50.0,
// "skuId": 756135869099532315,
// "skuType": 1
async function coinBuy() {
  await OpenVipApi.coinBuy({
    eventId: '',
    price: text.value.discountPrice,
    skuId: text.value.id,
    skuType: tabController.value === 0 ? 1 : 2
  })
}

function rotateImage() {
  rotation.value += 360
  getCoin()
}

const imageStyle = computed(() => ({
  transform: `rotate(${rotation.value}deg)`,
  transition: 'transform 1s ease-in-out'
}))
</script>

<template>
  <div h-screen>
    <van-nav-bar title="开通会员" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="save">充值记录</button>
      </template>
    </van-nav-bar>
    <div relative m-4 h-25 rounded-md class="bg1">
      <img w-full src="/vipbg.png" />
      <div absolute left-5 top-5 ct-563934>
        <p flex flex-row items-center text-base>
          金币余额
          <img ml-2 h-5 w-5 :src="icon_hint" @click="showPay = true" />
        </p>
        <p flex flex-row items-center text-2xl>
          {{ money }}
          <img ml-2 h-6 w-6 :src="icon_refresh" :style="imageStyle" @click="rotateImage" />
        </p>
      </div>
      <div class="bg3 absolute right-4 top-8 h-8 w-20 rounded-25 text-center text-base leading-8 font-sans ct-FFFFFF">
        <NuxtLink to="/recharge"> 充值 </NuxtLink>
      </div>
    </div>
    <div m-4 mt-5 text-2xl ct-ffffff>会员卡</div>
    <van-swipe :loop="false" :show-indicators="false" :width="280" @change="onChange">
      <van-swipe-item v-for="item in list" :key="item.id" ml-2 mr-2>
        <div relative>
          <div absolute ml-15px mt-14px class="infor">
            <span
              rounded-11px
              px-11px
              py-2px
              text-size-10px
              ct-F4FAF7
              :style="`background: ${validDayColor(item.rightsLevel)};
                        color:${validDayTextColor(item.rightsLevel)}
            `"
            >
              {{ formatValidDay(item?.validDay) }}
            </span>
            <p mb-22px text-size-26px line-height-30px :style="`color:${priceTextColor(item.rightsLevel)}`">
              {{ item.name }}
            </p>
            <p text-12px line-through :style="`color:${priceTextColor(item.rightsLevel)}`">
              原价 {{ item.originalPrice }}
            </p>
            <p
              flex
              items-baseline
              gap-1
              text-42px
              line-height-30px
              :style="`color:${priceTextColor(item.rightsLevel)}`"
            >
              <span>{{ item.discountPrice }}</span>
              <BaseIcon name="gold" size="1.13" />
            </p>
          </div>
          <ImgWrapper :src="item.merchandiseUrl" h-37 w-70 />
        </div>
      </van-swipe-item>
    </van-swipe>
    <div mt-6>
      <div relative h-8 w-full flex items-center justify-center>
        <img h-3 w-58 :src="bg1" />
        <div absolute text-center text-lg ct-ffffff>会员卡权益</div>
      </div>
      <div ml-4 text-xs ct-e8e8e8 v-html="text.content" />
      <div mt-4 flex justify-center>
        <div round h-11 w-88 text-center ct-ffffff class="bg-bt" @click="show = true">立即购买</div>
      </div>
    </div>
    <van-popup v-model:show="show" closeable position="bottom" h-92>
      <div mt-4 text-center text-xl ct-e8e8e8>请确认支付结果</div>
      <div border-b-1 border-b-gray-500 pb-2 pt-2 text-center text-xs ct-e8e8e8>
        已选择 {{ text.name }} <span ct-FD5B03>{{ text.discountPrice }}个金币</span>
      </div>
      <div mt-8>
        <div flex flex-row items-center justify-center>
          <img mr-2 h-9 w-9 :src="icon_gold" />
          <span text-4xl ct-e8e8e8>{{ text.discountPrice }}</span>
        </div>
        <div ml-4 mt-8>
          <p text-sm ct-E8E8E8>支付提示：</p>
          <p text-xs leading-4 ct-ADAFB6>1、购买成功后，可在购买记录查询记录。</p>
          <p text-xs leading-4 ct-ADAFB6>2、支付遇到其他问题，可咨询在线客服处理。</p>
        </div>
        <div mt-8 flex justify-center>
          <div h-11 w-88 text-center ct-ffffff class="bg-bt" @click="pay">确认支付</div>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="showLoad" style="z-index: 9999" @click="showLoad = false">
      <div h-screen flex items-center justify-center @click.stop>
        <div flex items-center justify-center>
          <van-loading v-if="showLoading" vertical h-25 w-28 size="24px"> 正在提交订单 </van-loading>
          <van-loading v-if="showLoading1" vertical h-25 w-28 size="24px"> 正在完成支付 </van-loading>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showPay">
      <div h-full flex items-center justify-center>
        <div h-80 w-80 bg-111425>
          <div relative h-10 w-full text-center leading-10>
            <span text-lg ct-ffffff>金币价格</span>
            <img :src="icon_close" absolute right-2 top-1 h-8 w-8 @click="showPay = false" />
          </div>
          <div mt-4>
            <div h-10 w-full flex flex-row items-center justify-center ct-e8e8e8>
              1 <img h-6 w-7 :src="Frame" /> &nbsp;&nbsp; = &nbsp;&nbsp; 1 <img h-6 w-6 :src="icon_gold" />
            </div>
            <div h-10 w-full flex flex-row items-center justify-center ct-787fa8>
              <div h-6 w-32 rounded-xl text-center text-xs leading-6 bg-242943 class="dh">兑换比例:1元=1金币</div>
            </div>
            <div mt-8 h-20 w-full flex flex-col items-center justify-center ct-e8e8e8>
              <div h-6 w-50 text-center text-xs leading-6>充值赠送金币活动正在进行中!</div>
              <div h-6 w-50 text-center text-xs leading-6>冲的越多,送的越多!</div>
            </div>
            <div flex justify-center>
              <div h-11 w-68 text-center ct-ffffff class="bg-bt" @click="showPay = false">知道了，立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}

:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}

:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}

:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}

.bg1 {
  background: linear-gradient(96.52deg, #efbb76 3.96%, #efcf9a 94.87%);
}

.bg3 {
  background: linear-gradient(90deg, #fe9849 0.84%, #ff3e4e 99.19%, #fe8d21 100%);
}

.bg-bt {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  line-height: 44px;
  border-radius: 10px;
}

:deep(.van-popup) {
  background: #242943;
}

:deep(.van-loading--vertical) {
  background: #414660;
  color: #ffffff;
  justify-content: center;
}

:deep(.van-loading__text) {
  color: #ffffff;
}
</style>
