import { RightsLevel } from '~/enums/vip'

/**
 * vip 背景色
 * @param rightsLevel
 * @returns
 */
export const validDayColor = function (_rightsLevel?: number): string {
  const rightsLevel = _rightsLevel as RightsLevel
  console.log('rightsLevel', rightsLevel)
  switch (rightsLevel) {
    case RightsLevel.WhiteCard:
      return '#165EC2'
    case RightsLevel.StarWeekly:
      return '#DC5C7E'
    case RightsLevel.StarMonthly:
      return '#5B29B4'
    case RightsLevel.GoldAnnual:
      return '#8D6A21'
    case RightsLevel.BlackGoldAnnual:
    case RightsLevel.BlackGold:
      return '#F8D284'
    case RightsLevel.PlatinumLifetime:
      return '#C18472'
    default:
      return '#165EC2'
  }
}
/**
 * vip 文本色
 * @param rightsLevel
 * @returns
 */
export const validDayTextColor = function (_rightsLevel?: RightsLevel): string {
  const rightsLevel = _rightsLevel as RightsLevel
  switch (rightsLevel) {
    case RightsLevel.WhiteCard:
    case RightsLevel.StarWeekly:
    case RightsLevel.StarMonthly:
    case RightsLevel.GoldAnnual:
    case RightsLevel.PlatinumLifetime:
      return '#F4FAF7'
    case RightsLevel.BlackGoldAnnual:
    case RightsLevel.BlackGold:
      return '#5F4D25'
    default:
      return '#F4FAF7'
  }
}

/**
 * vip 价格色
 * @param rightsLevel
 * @returns
 */
export const priceTextColor = function (_rightsLevel?: RightsLevel): string {
  const rightsLevel = _rightsLevel as RightsLevel
  switch (rightsLevel) {
    case RightsLevel.WhiteCard:
      return '#033FB4'
    case RightsLevel.StarWeekly:
      return '#D8597B'
    case RightsLevel.StarMonthly:
      return '#692ED0'
    case RightsLevel.GoldAnnual:
      return '#9C7728'
    case RightsLevel.BlackGoldAnnual:
    case RightsLevel.BlackGold:
      return '#E6DCE0'
    case RightsLevel.PlatinumLifetime:
      return '#A66E5D'
    default:
      return '#033FB4'
  }
}
/**
 * vip 渐变
 * @param rightsLevel
 * @returns
 */
export const gradientColor = function (_rightsLevel?: RightsLevel): string[] {
  const rightsLevel = _rightsLevel as RightsLevel
  switch (rightsLevel) {
    case RightsLevel.WhiteCard:
      return ['#155CC1', '#033FB4']
    case RightsLevel.StarWeekly:
      return ['#CB4F70', '#EB698B']
    case RightsLevel.StarMonthly:
      return ['#7934F0', '#5B29B4']
    case RightsLevel.GoldAnnual:
      return ['#BC923A', '#8A681F']
    case RightsLevel.BlackGoldAnnual:
    case RightsLevel.BlackGold:
      return ['#FFECBD', '#F6CE7B']
    case RightsLevel.PlatinumLifetime:
      return ['#E19F7D', '#A46C5C']
    default:
      return ['#155CC1', '#033FB4']
  }
}
